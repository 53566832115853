<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-30'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("faq") }}
				</AppText>
			</div>
		</div>
		<div class="content__holder" :class="isMobileMedium ? 'flex-wrap' : ''">
			<div
				class="sidebar__main"
				:class="[
					isMobile ? '' : isDesktopSmall ? 'mr-20' : 'mr-40',
					isDesktopSmall ? 'mb-30' : '',
					isDesktopSmall ? 'max-width-100' : ''
				]"
			>
				<AppText weight="700" size="16" line-height="21" class="sidebar__main-title mb-20">
					{{ $t("questionCategories") }}:
				</AppText>

				<ul class="sidebar__list">
					<li class="sidebar__list-item" v-for="(item, index) in sidebar" :key="index">
						<router-link :to="item.path" exact="" class="sidebar__list-link">
							{{ item.name }}
						</router-link>
					</li>
				</ul>
			</div>

			<div class="content__main">
				<Accordion>
					<AccordionItem v-for="(item, idx) in 6" :key="idx" class="accordion__item mb-15">
						<template slot="accordion-trigger">
							<div class="accordion__header">
								<AppText size="16" line-height="21" weight="600">
									{{ idx + 1 }}. Что такое ЭЦП?
								</AppText>
							</div>
						</template>

						<template slot="accordion-content">
							<div class="pa-20">
								<AppText size="14" weight="400" line-height="18">
									ЭЦП (электронная цифровая подпись) представляет собой специальную подпись,
									полученную методом криптографического преобразования данных с применением
									сертифицированного закрытого и открытого ключа, полностью заменяющую собой обычную
									подпись и позволяющую гарантировано подтвердить принадлежность подписи конкретной
									организации или человеку. Получить ЭЦП можно на сайте e-imzo.uz, а также
									воспользовавшись государственной услугой на ЕПИГУ. Инструкция по получению ЭЦП в
									режиме онлайн:
									<a href="https://my.gov.uz/ru/pages/ecp-online?new=1">
										https://my.gov.uz/ru/pages/ecp-online?new=1
									</a>
								</AppText>
							</div>
						</template>
					</AccordionItem>
				</Accordion>
			</div>
		</div>
	</main>
</template>

<script>
import Accordion from "../../components/shared-components/Accordion";
import AccordionItem from "../../components/shared-components/AccordionItem";
import AppText from "../../components/shared-components/AppText";

export default {
	name: "index",
	components: { AppText, AccordionItem, Accordion },
	data() {
		return {
			sidebar: [
				{
					name: this.$i18n.t("aboutPortal"),
					path: "/faq"
				},
				{
					name: this.$i18n.t("registrationQuestions"),
					path: "/"
				},
				{
					name: this.$i18n.t("scheduleQuestions"),
					path: "/"
				},
				{
					name: this.$i18n.t("scheduleAuctions"),
					path: "/"
				},
				{
					name: this.$i18n.t("scheduleShops"),
					path: "/"
				},
				{
					name: this.$i18n.t("selectionQuestions"),
					path: "/"
				},
				{
					name: this.$i18n.t("tenderQuestions"),
					path: "/"
				},
				{
					name: this.$i18n.t("questionsPayment"),
					path: "/"
				},
				{
					name: this.$i18n.t("questionsOperator"),
					path: "/"
				},
				{
					name: this.$i18n.t("popularQuestions"),
					path: "/"
				}
			]
		};
	}
};
</script>

<style scoped></style>
